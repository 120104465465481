import { get } from 'lodash'
import { hydrateWhenIdle, hydrateWhenVisible } from 'vue-lazy-hydration'

import AppTitle from '@theme/components/atom/AppTitle'
import CriticalContentMixin from '~/mixins/CriticalContentMixin'
import HomepageBranches from '@theme/components/content/HomepageBranches/HomepageBranchesLazy'
import HomepageTextBlock from '@theme/components/content/HomepageTextBlock'
import IntimateAdvisory from '@theme/components/shop/IntimateAdvisory'
import LayoutBase from '@theme/components/shop/LayoutBase'
import ProductsRecommended from '@theme/components/product/ProductsRecommended/ProductsRecommendedLazy'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import SexshopBlogArticles from '@theme/components/content/SexshopBlogArticles/SexshopBlogArticlesLazy'
import Slider from '@theme/components/content/Slider'
import TimeLimitedOfferBox from '@theme/components/product/TimeLimitedOfferBox'
const ArticleTeaser = () => import('@theme/components/content/ArticleTeaser')
const Divider = () => import('@theme/components/molecule/Divider')
const InfoBoxes = () => import('@theme/components/content/InfoBoxes')

const ProductBox = () => import('@theme/components/product/ProductBox')
const ProductTeaser = () => import('@theme/components/product/ProductTeaser')
const TopCategories = () => import('@theme/components/category/TopCategories')
const TopCategory = () => import('@theme/components/category/TopCategory')
const UniqueSellingPoints = () => import('@theme/components/shop/UniqueSellingPoints')

export default {
  mixins: [CriticalContentMixin, SeoMixin],
  components: {
    AppTitle,
    ArticleTeaser: hydrateWhenIdle(ArticleTeaser),
    Divider: hydrateWhenIdle(Divider),
    HomepageBranches,
    HomepageTextBlock,
    InfoBoxes: hydrateWhenIdle(InfoBoxes),
    IntimateAdvisory,
    LayoutBase,
    ProductBox: hydrateWhenVisible(ProductBox),
    ProductTeaser: hydrateWhenVisible(ProductTeaser),
    ProductsRecommended,
    ProductsVisited,
    SexshopBlogArticles,
    Slider: hydrateWhenIdle(Slider),
    TimeLimitedOfferBox,
    TopCategories: hydrateWhenIdle(TopCategories),
    TopCategory: hydrateWhenIdle(TopCategory),
    UniqueSellingPoints: hydrateWhenIdle(UniqueSellingPoints),
  },
  props: {
    blockHomepageContent: Object,
    featuredBanners: Array,
    featuredProducts: Object,
    infoBoxes: Array,
    latestArticles: Array,
    topCategories: Array,
  },
  head() {
    return this.getSeoHead()
  },
  computed: {
    criticalContentBreakpoints() {
      return get(this.$themeSettings, 'components.Slider.image.srcsetBreakpoints').length
        ? this.$themeSettings.components.Slider.image.srcsetBreakpoints
        : Slider.extends.props.srcsetBreakpointsDefaults.default(this.$themeSettings)
    },
    criticalContentImageRatio() {
      const width = this.$themeSettings.components.Slider.image.width
      const height = this.$themeSettings.components.Slider.image.height
      return 1 / (width / height)
    },
    criticalContentPaths() {
      const elements = []

      if (this.featuredBanners.length > 0) {
        const banner = this.featuredBanners[0]
        elements.push(
          banner.main_image_translatable && banner.main_image_translatable.length > 0
            ? banner.main_image_translatable[0].path
            : banner.main_image[0].path,
        )
      }

      return elements
    },
    showTimeLimitedOffer() {
      return (
        this.$themeSettings.components.PageHomepage.showTimeLimitedOfferBox &&
        this.blockHomepageContent?.limited_offer_reference?.[0]?.entity?.active
      )
    },
  },
  methods: {
    getLCPElements() {
      return this.getCriticalContentMetaPaths()
    },
  },
}
